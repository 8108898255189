import {
  DetailsRow,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Stack
} from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { DateTimeWithDurationSinceTooltip } from "@components/DateTimeWithDurationSinceTooltip";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { ScrollListener } from "@components/tables/ScrollListener";
import { SoftwarePackageLog } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { convertBytes } from "@libs/common/common.utils";

interface DeviceOnsiteLogTableProps {
  deviceLogs: SoftwarePackageLog[];
  error?: HttpError;
  isLoading?: boolean;
  hasNextPage: boolean | undefined;
  handleScrolledToBottom: () => Promise<void>;
}

export const DeviceOnsiteLogTable = ({
  deviceLogs,
  error,
  isLoading,
  hasNextPage,
  handleScrolledToBottom
}: DeviceOnsiteLogTableProps) => {
  const columns: DataTableColumn<SoftwarePackageLog>[] = [
    {
      key: "blobName",
      name: "Name",
      minWidth: 100,
      maxWidth: 450,
      isResizable: true,
      onRender: (deviceLog: SoftwarePackageLog) => (
        <Link href={deviceLog.blobDownloadUrl}>{deviceLog.blobName}</Link>
      )
    },
    {
      key: "blobFileSize",
      name: "FileSize",
      minWidth: 100,
      isResizable: true,
      onRender: (deviceLog: SoftwarePackageLog) =>
        convertBytes(deviceLog.blobFileSize)
    },
    {
      key: "blobCreationDate",
      name: "Blob Created Date",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isSorted: true,
      sort: true,
      isSortedDescending: true,
      onRender: (deviceLog: SoftwarePackageLog) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={deviceLog.blobCreationDate}
        />
      )
    },
    {
      key: "blobLastModifiedDate",
      name: "Blob Last Modified Date",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (deviceLog: SoftwarePackageLog) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={deviceLog.blobLastModifiedDate}
        />
      )
    }
  ];

  return (
    <Stack
      grow
      styles={{ root: { minHeight: 500, position: "relative" } }}
      verticalFill
    >
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DataTable
          enableShimmer={isLoading}
          errorMessage={error?.message}
          isSelectedOnFocus
          columns={columns}
          items={deviceLogs}
          onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
        />
        <ScrollListener
          hasNextPage={hasNextPage ?? false}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
